@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.heading {
    font-family: 'Roboto', sans-serif;
}

.sub-heading {
    font-family: 'Inter', sans-serif;
}

.bg-blur {
    filter: blur(95.30303955078125px);
}

.bg-shadow {
    -webkit-box-shadow: -10px 12px 68px 0px rgba(0, 88, 119, 0.25);
    -moz-box-shadow: -10px 12px 68px 0px rgba(0, 88, 119, 0.25);
    box-shadow: -10px 12px 68px 0px rgba(0, 89, 119, 0.25);
}

.footer-text {
    font-family: 'Poppins', sans-serif;
}

.button-bg {
    border-radius: 36.449px;
    border: 0.729px solid #FFF;
    background: linear-gradient(96deg, rgba(0, 89, 119, 0.55) 2.77%, rgba(169, 223, 216, 0.70) 99.19%);
    backdrop-filter: blur(7.28971529006958px);
}

.custom-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: transparent #005977;
}

.custom-scrollbar::-webkit-scrollbar {
    width: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    /* background-color: #005977; */
    background-color: transparent;
}

.custom-scrollbar::-webkit-scrollbar-track {
    background-color: transparent;
}

.bg-linear {
    background: linear-gradient(149deg, #005977 12.9%, #5DBAD9 29.15%, #2CB2DF 41.54%, #005977 70.18%, #005977 87.21%);
}